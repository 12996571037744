import React, { useState } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import DrawerMenu from "../drawerMenu/drawerMenu"
import "./style.scss"
const Header = ({ siteTitle }) => {
  return (
    <header
      id="header"
      style={{
        backgroundColor: `white`,
        padding: "20px 0",
      }}
    >
      <div className="container" style={{}}>
        <div className="row justify-content-between align-items-start align-items-md-center">
          <div
            className="d-block d-md-none"
            style={{ cursor: "pointer", paddingLeft: "15px" }}
          >
            <DrawerMenu>
              <StaticImage
                width={25}
                className="d-block d-md-none"
                loading="lazy"
                src="../../assets/images/菜单图标.png"
                objectFit="unset"
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="menu"
                title="menu"
              />
            </DrawerMenu>
          </div>
          <h1
            style={{
              margin: 0,
              color: "transparent",
              fontSize: "0",
              paddingLeft: "15px",
              paddingRight: "15px",
            }}
            className="webName"
          >
            海纳宏益
            <Link
              to="/"
              style={{
                textDecoration: `none`,
              }}
            >
              <StaticImage
                loading="lazy"
                placeholder="blurred"
                src="../../assets/images/ydd-海纳宏益-logo.png"
                // src="../../assets/images/logo-顶部.png"
                objectFit="unset"
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="海纳宏益"
                title="海纳宏益"
              />
            </Link>
          </h1>
          <ul
            className="headerNav d-md-block  d-none"
            style={{ marginBottom: 0 }}
          >
            <li>
              <Link to="/">首页</Link>
            </li>
            <li>
              <Link to="/about">企业简介</Link>
            </li>
            <li>
              <Link to="/service">服务项目</Link>
            </li>
            <li>
              <Link to="/contact">联系我们</Link>
            </li>
            {/* <li>
              <Link to="/recruit">招贤纳士</Link>
            </li> */}
          </ul>
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
