import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import "./style.scss"

const Footer = ({ siteTitle }) => (
  <footer
    className="footer"
    css={{
      background: `#565D66`,
      padding: "54px 0 80px",
    }}
  >
    <div className="container">
      <div className="row justify-content-between align-items-center">
        <div
          className="d-none d-md-block"
          style={{
            color: "#FFFFFF",
            paddingLeft: "15px",
            paddingRight: "15px",
          }}
        >
          <div
            style={{
              paddingBottom: "9px",
            }}
          >
            <StaticImage
              loading="lazy"
              placeholder="blurred"
              src="../../assets/images/logo-底部.png"
              width={43}
              objectFit="unset"
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="海纳宏益"
              title="海纳宏益"
            />
          </div>
          <h2
            css={{
              fontSize: "28px",
              paddingBottom: "14px",
            }}
          >
            海纳宏益科技有限责任公司
          </h2>
          <p
            style={{
              fontSize: "18px",
            }}
          >
            期待您的加入，未来因你而来
          </p>
        </div>
        <div
          className="d-none d-md-block footerNav justify-content-around "
          style={{
            color: "#FFFFFF",
            paddingLeft: "15px",
            paddingRight: "15px",
            marginTop: "20px",
          }}
        >
          <Link to="/about">企业简介</Link>
          <Link to="/service">服务项目</Link>
          {/* <Link to="/recruit">招贤纳士</Link> */}
          <Link to="/contact">联系我们</Link>
        </div>
      </div>
      <div className="text-md-left text-center copyright">
        ©2021 福州海纳宏益科技有限责任公司 
        <span className="d-md-inline-block d-none" css={{margin:'0 10px'}}> | </span>
        <br className="d-md-none d-block" />
        <a href="https://beian.miit.gov.cn" target="_blank">闽ICP备2021003922号-1</a>
      </div>
      <div className="IPv6 d-md-none d-block text-md-left text-center">
        本网站支持 <span className="">IPv6</span>
      </div>
    </div>
  </footer>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
